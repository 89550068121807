import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const ElectricalProductSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-left p-5 pt-5 pb-5`}
      style={{
        background: "#EFEFEF",
        color: "#365c70",
      }}
    >
      <div className="container">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          Electrical
        </div>

        <hr
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: "",
          }}
        />


       <div class="container m-0 p-0 mt-3 mb-5 d-none d-sm-block">
          <div class="row">
            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/electrical/1.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/electrical/2.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/electrical/3.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>

       <div class="container m-0 p-0 d-block d-sm-none">
          <img
            data-aos="Fade"
            src="/electrical/2.jpg"
            loading="lazy"
            decoding="async"
            alt=""
            width="100%"
          />
        </div>


        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
         <div
           className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
           style={{ fontSize: "18px", color: "#365c70" }}
         >
         </div>

         <div
            className="hidden-sm hidden-md hidden-lg hidden-xl mb-5 p-0"
            style={{ fontSize: "18px", color: "" }}
          >
            Norhart Supply carries several electrical products such as various kinds of wire and cable, outlets, switches, boxes and conduit to complete high and low voltage projects.  As well as electrical materials, Norhart Supply also has a wide variety of modern lighting fixtures.  We carry a large selection of lighting for all areas, both interior and exterior.

            <p/>

            <strong>Wire & cable</strong>
            <br/>
            Norhart Supply carries various kinds of cable and wire for high voltage and low voltage projects.  High voltage is any voltage over 1000 volts, air conditioners and washers & dryers fit in this category.  Anything below 600 volts is considered low voltage, things such as fire protection systems and security systems.  From fiber optic cables to ensure the highest speeds of internet, to service cabling, Norhart Supply has it all.
            <p/>
            <hr/>

            <strong>Outlets & switches</strong>
            <br/>
            Along with wire and cable, Norhart Supply carries outlets and switches for electrical jobs.  Outlets supply power to devices such as lamps, small cooking appliances, and phone or computer chargers.  Norhart Supply also carries switches for lighting.  We have regular switches, paddle and dimmer switches.
            <p/>
            <hr/>

            <strong>Boxes</strong>
            <br/>
            Norhart Supply carries electrical boxes.  Electrical boxes, also known as junction boxes, are used to enclose wire connections.  These boxes are used to splice wire in and support electrical devices.  Norhart Supply has boxes in numerous sizes and shapes to fit into the area where you are placing it.
            <p/>
            <hr/>

            <strong>Lighting</strong>
            <br/>
            As well as various electrical materials, Norhart Supply also has an assortment of lighting fixtures.  We carry a variety of modern lighting fixtures to brighten up any area of your business, home, or building.  We carry fixtures such as chandeliers, sconce lights, and can lights for indoors.  We also carry exterior lighting fixtures for home & building entrances, and decks & patios.
            <p/>
            <hr/>

            <strong>Conduit</strong>
            <br/>
            Norhart Supply also carries conduit.  Conduit is used to protect wires and those who may otherwise encounter the wires.  There are 7 kinds of conduit—Rigid Metal Conduit (RMC), Electrical Metallic Tubing (EMT), Intermediate Metal Conduit (IMC), Flexible Metal Conduit (FMC), Electrical Non-Metallic Tubing (ENT), Liquid-Tight Flexible Metal Conduit (LFMC) and Rigid PVC.  Norhart Supply has the conduit you need to suit any electrical project.
            <p/>
            <hr/>

            <strong>Load Center</strong>
            <br/>
            At Norhart Supply, we carry load centers as well as numerous other electrical materials.  Load centers, such as breaker boxes and fuse boxes, take electricity from the utility source and distribute the electricity throughout a building.  This distribution of electricity from a load center is protected from over-current by circuit breakers and fuses.  A load center is a key component to any electrical system.
            <p/>

          </div>
        </div>
      </div>
    </section>
  )
}

export default ElectricalProductSection
